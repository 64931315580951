
  export const style={
    inpuStyle:{
      colorInput:"#CC0707"
    },
    url:{
      urlClient:"https://nacaovalquirias.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.us-east-1.amazonaws.com/Logos/primary_logo_client_valquirias.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: true,
       autoRegister: false,
    },
    CurrencyName:{
      name:"Coroa/Coroas"
    },
    teamName:{
      name:"Nacao Valquirias"
    },
    hasToken: false,
    hostHeader: "valquirias"
  }
