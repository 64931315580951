import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { debounce } from 'lodash';
import { browserHistory } from 'react-router';
import {
  DropDownDefaultById,
  DropDownDefaultOnlyOne,
  TextFieldWithoutForm
} from '../../../utils/FormsComponents';
import { SelectForm } from '../../../utils_form/FormComponents';
import { required } from '../../../validation/FormValidation';
import SectorApi from '../../../../Logicas/SectorApi';
import TagApi from '../../../../Logicas/TagApi';
import UserApi from '../../../../Logicas/UserApi';
import DownloadPostData from '../../../_shared/DownloadPostData/DownloadPostData';
import teamSettings from '../../../utils/TeamsSettings/generalSettings';
import { teamsActivities } from '../../../utils/TeamsActivities/teamsActivities';

import { style } from '../../../../style';
import * as S from './UsersHeader.styles';

const { userFilter } = teamSettings();

export class UsersHeader extends Component {
  constructor() {
    super();
    this.state = {
      isTypeFilterActive: false,
      selectedFilter: '',
      filterValue: '',
      searchValue: '',
      searchTag: '',
      searchSector: ''
    };

    this.getTagByName = this.getTagByName.bind(this);
    this.getTagByNameDebounced = this.getTagByNameDebounced.bind(this);
    this.getSectorByName = this.getSectorByName.bind(this);
    this.getSectorByNameDebounced = this.getSectorByNameDebounced.bind(this);
    this.getActivities = this.getActivities.bind(this);
  }

  quickActionsMap = {
    'Enviar notificações': () => this.props.ShowDialogNotification(),
    'Pontuar usuários': () => this.props.ShowDialogScore(),
    'Atribuir tag': () => this.props.ShowDialogTag(),
    'Editar campos personalizáveis': () =>
      browserHistory.push('/user/customfields'),
    'Definir mensagem de aniversário': () => {
      !this.props.birthdayMessage
        ? this.props.getBirthdayMessage()
        : this.props.showBirthdayMessageModal();
    },
    'Reenviar e-mail de boas vindas': () => {
      this.props.users_count === 0
        ? this.props.getNotLoggedUsersCount()
        : this.props.showResendWelcomeDialog();
    }
  };

  quickActionsMapPRIO = {
    'Enviar notificações': () => this.props.ShowDialogNotification(),
    'Pontuar usuários': () => this.props.ShowDialogScore(),
    'Pontuar usuários via planilha': () => this.props.openNewDialogScore(),
    'Atribuir tag': () => this.props.ShowDialogTag(),
    'Editar campos personalizáveis': () =>
      browserHistory.push('/user/customfields'),
    'Definir mensagem de aniversário': () => {
      !this.props.birthdayMessage
        ? this.props.getBirthdayMessage()
        : this.props.showBirthdayMessageModal();
    },
    'Reenviar e-mail de boas vindas': () => {
      this.props.users_count === 0
        ? this.props.getNotLoggedUsersCount()
        : this.props.showResendWelcomeDialog();
    }
  };

  quickActionsMapSuvinil = {
    'Enviar notificações': () => this.props.ShowDialogNotification(),
    'Pontuar usuários': () => this.props.ShowDialogScore(),
    'Atribuir tag': () => this.props.ShowDialogTag(),
    'Atribuir tag via planilha': () =>
      this.props.openNewDialogSpreadsheetTagAssignment(),
    'Editar campos personalizáveis': () =>
      browserHistory.push('/user/customfields'),
    'Definir mensagem de aniversário': () => {
      !this.props.birthdayMessage
        ? this.props.getBirthdayMessage()
        : this.props.showBirthdayMessageModal();
    },
    'Reenviar e-mail de boas vindas': () => {
      this.props.users_count === 0
        ? this.props.getNotLoggedUsersCount()
        : this.props.showResendWelcomeDialog();
    }
  };

  quickActionsAllMap = {
    'Enviar notificações': () => this.props.ShowDialogNotification(),
    'Pontuar usuários': () => this.props.ShowDialogScore(),
    'Pontuar usuários via planilha': () => this.props.openNewDialogScore(),
    'Atribuir tag': () => this.props.ShowDialogTag(),
    'Atribuir tag via planilha': () =>
      this.props.openNewDialogSpreadsheetTagAssignment(),
    'Editar campos personalizáveis': () =>
      browserHistory.push('/user/customfields'),
    'Definir mensagem de aniversário': () => {
      !this.props.birthdayMessage
        ? this.props.getBirthdayMessage()
        : this.props.showBirthdayMessageModal();
    },
    'Reenviar e-mail de boas vindas': () => {
      this.props.users_count === 0
        ? this.props.getNotLoggedUsersCount()
        : this.props.showResendWelcomeDialog();
    }
  };

  // Search TAG

  getTagByName({ target: { value } }) {
    this.setState({ searchTag: value }, () => {
      this.getTagByNameDebounced(1, value);
    });
  }

  getTagByNameDebounced = debounce((page, search) => {
    this.props.getTags(page, search);
  }, 1500);

  // Search SECTOR

  getSectorByName({ target: { value } }) {
    this.setState({ searchSector: value }, () => {
      this.getSectorByNameDebounced(1, value);
    });
  }

  getSectorByNameDebounced = debounce((page, search) => {
    this.props.getSectorUser(page, search);
  }, 1500);

  // componentDidMount() {
  //   const { getSectorUser } = this.props;
  //   getSectorUser();
  // }

  resetUsersFilter = () => {
    const { getFilterUsers } = this.props;

    getFilterUsers('Página 1');
  };

  filterUsersByName = debounce((filterValue) => {
    const { getFilterUsers } = this.props;

    getFilterUsers('', '', filterValue);
  }, 1500);

  filterUsersByGender = (filterValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;
    getFilterUsers(selectedFilter, filterValue);
  };

  filterUsersByActivity = (filterValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, filterValue);
  };

  filterUsersByBirthday = (filterValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, filterValue);
  };

  filterUsersBySector = (filterValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, filterValue);
  };

  filterUserByTags = (tags) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    this.setState({ filterValue: tags });

    getFilterUsers(selectedFilter, tags);
  };

  filterUsersByLogin = (filterValue) => {
    const { getFilterUsers } = this.props;
    const { selectedFilter } = this.state;

    getFilterUsers(selectedFilter, filterValue);
  };

  getActivities() {
    this.props.getActivities(false);
  }

  componentDidMount() {
    if (
      style.teamName.name === 'Vedacit' ||
      style.teamName.name === 'mestreconstrutor' ||
      style.teamName.name === 'Ecom'
    ) {
      this.getActivities();
    }
  }

  render() {
    const {
      resetUsersFilter,
      filterUsersByName,
      filterUsersByGender,
      filterUsersByBirthday,
      filterUsersBySector,
      filterUserByTags,
      filterUsersByLogin,
      filterUsersByActivity
    } = this;
    const {
      isTypeFilterActive,
      filterValue,
      searchValue,
      selectedFilter
    } = this.state;
    const {
      sectors,
      sectorsV2,
      list_marked,
      unselectAllUsers,
      clearFilterStore,
      listTagName,
      activities
    } = this.props;
    const heightDropdown = listTagName.length * 34 + 70;
    const heightDropdownSector = sectorsV2.length * 34 + 70;
    const dropdownMenuProps = {
      menuStyle: {
        height: `${heightDropdown}px`,
        overflow: 'hidden'
      }
    };
    const dropdownMenuSectorProps = {
      menuStyle: {
        height: `${heightDropdownSector}px`,
        overflow: 'hidden'
      }
    };

    return (
      <S.UsersHeaderContainer className="container">
        <S.MainRow>
          <S.TitleContainer>
            <S.Title>Usuários</S.Title>
          </S.TitleContainer>
          <S.NewUserContainer>
            <S.Button
              onClick={() => {
                return browserHistory.push('/user/create');
              }}
            >
              Novo usuário
            </S.Button>
          </S.NewUserContainer>
        </S.MainRow>
        <S.SecondaryRow>
          {!isTypeFilterActive && (
            <TextFieldWithoutForm
              value={searchValue}
              onChange={(event, value) =>
                this.setState({ searchValue: value }, () =>
                  filterUsersByName(value)
                )
              }
              floatingLabelText="Busque por usuários"
            />
          )}
          <S.DropdownGroup>
            <div>
              <DropDownDefaultOnlyOne
                multiple={false}
                value={selectedFilter}
                onChange={(event, index, selectedFilter) => {
                  this.setState({
                    isTypeFilterActive: true,
                    selectedFilter
                  });
                  selectedFilter === 'Tags' &&
                    this.props.listTagName.length === 0 &&
                    this.props.getTags(1);
                  selectedFilter === 'Setor' &&
                    sectorsV2.length === 0 &&
                    this.props.getSectorUser(1);
                }}
                array={userFilter}
                floatingLabelText={
                  isTypeFilterActive
                    ? 'Filtrando por:'
                    : 'Filtrar usuários por:'
                }
              />
            </div>
            {selectedFilter === 'Gênero' && (
              <div>
                <DropDownDefaultOnlyOne
                  multiple={false}
                  value={filterValue}
                  onChange={(event, index, filterValue) =>
                    this.setState(
                      { filterValue },
                      filterUsersByGender(filterValue)
                    )
                  }
                  array={[
                    'Mulher cis',
                    'Mulher trans',
                    'Homem cis',
                    'Homem trans',
                    'Não binário',
                    'Prefere não se identificar',
                    'Outros'
                  ]}
                  floatingLabelText="Selecione o Gênero"
                />
              </div>
            )}
            {selectedFilter === 'Mês do Aniversário' && (
              <div>
                <DropDownDefaultOnlyOne
                  multiple={false}
                  value={filterValue}
                  onChange={(event, index, filterValue) =>
                    this.setState(
                      { filterValue },
                      filterUsersByBirthday(filterValue)
                    )
                  }
                  array={[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                  ]}
                  floatingLabelText="Selecione o mês"
                />
              </div>
            )}
            {selectedFilter === 'Setor' && (
              <div>
                <>
                  <TextFieldWithoutForm
                    value={this.state.searchSector}
                    onChange={this.getSectorByName}
                    floatingLabelText="Digite o nome do setor"
                    style={{ zIndex: '3' }}
                  />
                  <DropDownDefaultById
                    multiple={false}
                    value={filterValue}
                    onChange={(event, index, filterValue) =>
                      this.setState(
                        { filterValue },
                        filterUsersBySector(filterValue)
                      )
                    }
                    array={sectorsV2}
                    floatingLabelText="Selecione o setor"
                    withPagination={true}
                    style={{ width: '350px', padding: '0', zIndex: '999' }}
                    activePage={this.props.sectorsPagination.current_page}
                    totalItemsCount={this.props.sectorsPagination.total_count}
                    handlePageChange={(pageNumber) =>
                      this.props.getSectorUser(
                        pageNumber,
                        this.state.searchSector
                      )
                    }
                    dropdownMenuProps={dropdownMenuSectorProps}
                  />
                </>
              </div>
            )}
            {selectedFilter === 'Tags' && (
              <div style={{ width: '255px' }}>
                <>
                  <TextFieldWithoutForm
                    value={this.state.searchTag}
                    onChange={this.getTagByName}
                    floatingLabelText="Digite o nome da tag"
                    style={{ zIndex: '3' }}
                  />
                  <DropDownDefaultOnlyOne
                    multiple={true}
                    value={this.state.filterValue}
                    onChange={(event, index, tags) => {
                      filterUserByTags(tags);
                    }}
                    array={this.props.listTagName}
                    floatingLabelText="Selecione a Tag"
                    withPagination={true}
                    style={{ width: '350px', padding: '0', zIndex: '999' }}
                    activePage={this.props.tagsPagination.current_page}
                    totalItemsCount={this.props.tagsPagination.total_count}
                    handlePageChange={(pageNumber) =>
                      this.props.getTags(pageNumber, this.state.searchTag)
                    }
                    dropdownMenuProps={dropdownMenuProps}
                  />
                </>
              </div>
            )}
            {selectedFilter === 'Login' && (
              <div style={{ width: '255px' }}>
                <DropDownDefaultOnlyOne
                  multiple={false}
                  value={filterValue}
                  onChange={(event, index, filterValue) =>
                    this.setState(
                      { searchValue },
                      filterUsersByLogin(filterValue)
                    )
                  }
                  array={['Já realizaram login', 'Não realizaram login']}
                  floatingLabelText="Status de Login"
                />
              </div>
            )}
            {(selectedFilter === 'Atividade' ||
              selectedFilter === 'Profissões') && (
              <div>
                <DropDownDefaultOnlyOne
                  multiple={false}
                  value={filterValue}
                  onChange={(event, index, filterValue) =>
                    this.setState(
                      { filterValue },
                      filterUsersByActivity(filterValue)
                    )
                  }
                  elementsObject={
                    (style.teamName.name === 'Vedacit' ||
                      style.teamName.name === 'mestreconstrutor' ||
                      style.teamName.name === 'Ecom') &&
                    teamsActivities[style.teamName.name]
                  }
                  array={
                    style.teamName.name === 'Pintou Parceria Suvinil' &&
                    teamsActivities[style.teamName.name]
                  }
                  floatingLabelText={`Selecione a ${
                    selectedFilter === 'Atividade' ? 'Atividade' : 'Profissão'
                  }`}
                />
              </div>
            )}
          </S.DropdownGroup>
          <S.ActionsGroup>
            <DropDownDefaultOnlyOne
              multiple={false}
              value={selectedFilter}
              onChange={(event, index, actionValue) =>
                style.teamName.name === 'PetroRio'
                  ? this.quickActionsMapPRIO[actionValue]()
                  : style.teamName.name === 'Pintou Parceria Suvinil'
                  ? this.quickActionsMapSuvinil[actionValue]()
                  : style.teamName.name === 'sample' ||
                    style.teamName.name === 'Nacao Valquirias'
                  ? this.quickActionsAllMap[actionValue]()
                  : this.quickActionsMap[actionValue]()
              }
              array={
                style.teamName.name === 'PetroRio'
                  ? Object.keys(this.quickActionsMapPRIO)
                  : style.teamName.name === 'Pintou Parceria Suvinil'
                  ? Object.keys(this.quickActionsMapSuvinil)
                  : style.teamName.name === 'sample' ||
                    style.teamName.name === 'Nacao Valquirias'
                  ? Object.keys(this.quickActionsAllMap)
                  : Object.keys(this.quickActionsMap)
              }
              floatingLabelText={'Ações rápidas'}
            />
          </S.ActionsGroup>
        </S.SecondaryRow>
        <S.TertiaryRow>
          {isTypeFilterActive && (
            <S.ClearFilterButton>
              <S.ClearFilterlabel
                onClick={() =>
                  this.setState(
                    {
                      isTypeFilterActive: false,
                      selectedFilter: '',
                      searchValue: '',
                      filterValue: '',
                      searchTag: '',
                      searchSector: ''
                    },
                    () => [resetUsersFilter(), clearFilterStore()]
                  )
                }
              >
                Limpar filtros
              </S.ClearFilterlabel>
              <S.Underline />
            </S.ClearFilterButton>
          )}
          {!!list_marked.length && (
            <S.ClearFilterButton>
              <S.ClearFilterlabel onClick={() => unselectAllUsers()}>
                Limpar usuários
              </S.ClearFilterlabel>
              <S.Underline />
            </S.ClearFilterButton>
          )}
          <DownloadPostData kind="user" />
        </S.TertiaryRow>
      </S.UsersHeaderContainer>
    );
  }
}

UsersHeader = reduxForm({ form: 'UsersHeader' })(UsersHeader);
const selector = formValueSelector('UsersHeader');

const mapDispatchToProps = (dispatch) => {
  return {
    getFilterUsers: (filter_selectd, value_filter, name_user) => {
      dispatch(UserApi.getFilterUsers(filter_selectd, value_filter, name_user));
    },
    getUserPage: (id) => {
      dispatch(UserApi.getUserPage(id));
    },
    ShowDialogScore: () => {
      dispatch({ type: 'OPEN_DIALOG_SCORE', alert: true });
    },
    openNewDialogScore: () =>
      dispatch({
        type: 'OPEN_DIALOG_PONTUATION_USERS'
      }),
    openNewDialogSpreadsheetTagAssignment: () =>
      dispatch({
        type: 'OPEN_DIALOG_SPREADSHEET_TAG_ASSIGNMENT_USERS'
      }),
    ShowDialogNotification: () => {
      dispatch({ type: 'OPEN_DIALOG_NOTIFICATION_USERS', alert: true });
    },
    getSectorUser: (page, search) => {
      dispatch(SectorApi.getSectorUserV2(page, search));
    },
    clearFilterStore: () => {
      dispatch({ type: 'USER_FILTER_CLEAR' });
    },
    ShowDialogTag: () => {
      dispatch({ type: 'SHOW_MODAL_INSERT_TAG' });
    },
    getTags: (page, search) => {
      dispatch(TagApi.getTagsUserV2(page, search));
    },
    getNotLoggedUsersCount: () => dispatch(UserApi.getNotLoggedUsersCount()),
    getBirthdayMessage: () => {
      dispatch(UserApi.getBirthdayMessage());
    },
    unselectAllUsers: () => {
      dispatch({
        type: 'USER_SELECTED_NONE'
      });
    },
    showBirthdayMessageModal: () => {
      dispatch({
        type: 'BIRTHDAY_MESSAGE_MODAL_SHOW'
      });
    },
    showResendWelcomeDialog: () => {
      dispatch({ type: 'SHOW_RESEND_WELCOME' });
    },
    getActivities: (reset) => {
      dispatch(UserApi.getActivities({ reset }));
    }
  };
};
const UsersHeaderContainer = connect(
  (state) => ({
    list_marked: state.user.selectedUsers.users,
    sectors: state.sectorReducer.sectorList,
    sectorsV2: state.sectorReducer.sectorListV2,
    sectorsPagination: state.sectorReducer.paginationV2,
    // tags_selected: selector(state, 'tags'),
    listTagName: state.tagsWithPagination.tags,
    tagsPagination: state.tagsWithPagination.pagination,
    users_count: state.dialog_resend_welcome.users_count,
    birthdayMessage: state.user.birthdayMessage.birthdayMessageBody,
    activities: state.get_activities.data
  }),
  mapDispatchToProps
)(UsersHeader);
export default UsersHeaderContainer;
